@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;400;700;900&display=swap');
@font-face {
  font-family: 'Bing';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-10@1.0/BinggraeSamanco-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.ui {
  font-family: 'Noto Sans KR', sans-serif !important;
}

body {
  /*background-color : #F2F2F2*/
  background-color : #EFF0F1
}


.noScrollBar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.noScrollBar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}
