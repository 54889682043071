@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;400;700;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Bing';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-10@1.0/BinggraeSamanco-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.ui {
  font-family: 'Noto Sans KR', sans-serif !important;
}

body {
  /*background-color : #F2F2F2*/
  background-color : #EFF0F1
}


.noScrollBar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.noScrollBar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}

